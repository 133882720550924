import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import { Link } from 'gatsby'
import Img1 from '../images/new/blog/oficina-inclusiva.png'
import Img2 from '../images/new/blog/na-escola-a-gente-controi.png'
import Img3 from '../images/new/blog/post/image-8.jpeg'
import Img4 from '../images/new/blog/post/image-5.jpeg'
import Img5 from '../images/new/blog/post/image.jpeg'
import Img6 from '../images/new/blog/post/jornada/img-2.jpg'

import { 
  section,
  HeaderBanner,
  container,
  content,
  columns,
  column,
} from '../components/styles/Base.styles'

const BlogPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Leads | Um reino de aprendizado e conhecimento" />
    <NavBar />
    {/* <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div> */}
    <div css={[section, content]} style={{ paddingBottom: 0, marginTop: 112 }}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <div>
              <iframe src="https://forms.monday.com/forms/embed/9ab545f2150068b35321d58a1991223a?r=use1" width="100%" height="1900"  />
            </div>
          </div>
        </div>
        <div style={{ paddingBottom: 112 }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BlogPage
